/**
 * Profile component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"

const Profile = () => {
  return (
    <div id="profile">
      <div id="caption">
        <p>Hello 👋</p>
        <legend>Chike Arthur</legend>
        <legend>Ozulumba</legend>
        <br />
        <p>Professional Software Engineer with over 5 years of experience in software engineering.</p>
        <br />
        <p>👷 Currently building at <a href="https://lenkie.com" rel="noreferrer" target="_blank">Lenkie</a></p>
      </div>
    </div>
  )
}

export default Profile
