import * as React from "react"

import Seo from "../components/seo"
import Profile from "../components/profile"
import Layout from "../components/layout";

const IndexPage = () => (
  <Layout>
    <Seo title="Profile" />
    <div id="content">
      <Profile />
    </div>
  </Layout>
)

export default IndexPage
